import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ResponsiveContainer, ComposedChart, Line, Area, Legend, Tooltip, Bar, CartesianGrid, YAxis, XAxis } from "recharts";
import useStyles from "./styles";
import Widget from "./Widget";
import { Typography } from "./Wrappers";
import TripStatistics from "./TripStatistics/TripStatistics";
import RevenueStatistics from "./RevenueStatistics/RevenueStatistics";
import MapContainer from "./MapContainer/MapContainer";
import axios from 'axios';
import { authHeader, history } from '../../functions/_helpers';
import { SemipolarLoading } from 'react-loadingg';
import CabList from '../cab/cabList';

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [cabData, setCabData] = useState([]);
  const [tripStatisticsData, setTripStatisticsData] = useState();
  const [revenueStatisticsData, setRevenueStatisticsData] = useState([]);
  const [specialLocations, setSpecialLocations] = useState([]);
  const [tripTrendData, setTripTrendData] = useState();
  const [centerLatitude, setCenterLatitude] = useState(0);
  const [centerLongitude, setCenterLongitude] = useState(0);
  const [regions, setRegions] = useState([]);
  const classes = useStyles();
  const theme = useTheme();

  const fetchRegions = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/regions/', { headers: authHeader() });
      setRegions(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCabStatus = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/dashboard/cabs/', { headers: authHeader() });
      const data = response.data;
      setCabData(data);
    } catch (error) {
      console.error('Error fetching cab status:', error);
      setCabData([]);
    }
  };

  const fetchTripStatistics = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/dashboard/tripStatistics', { headers: authHeader() });
      setTripStatisticsData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTripTrendData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/dashboard/tripTrendData/29', { headers: authHeader() });
      setTripTrendData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRevenueStatistics = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      if (!apiUrl) throw new Error('API URL is not defined');
      const headers = authHeader();
      if (!headers) throw new Error('Authentication headers are not defined');
      const response = await axios.get(`${apiUrl}/api/dashboard/revenueStatistics`, { headers });
      const data = response.data;
      if (data) {
        const revenueData = Object.values(data).map(item => ({
          daily: item.total.daily || 0,
          weekly: item.total.weekly || 0,
          monthly: item.total.monthly || 0,
        }));
        console.log(revenueData)
        setRevenueStatisticsData(revenueData);
      } else {
        setRevenueStatisticsData([]);
      }
    } catch (error) {
      console.error('Error fetching revenue statistics:', error);
    }
  };

  const fetchSpecialLocations = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL + '/api/dashboard/specialLocations', { headers: authHeader() });
      setSpecialLocations(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        localStorage.removeItem('user');
        localStorage.clear();
        history.push('/login');
      } else {
        return Promise.reject(error);
      }
    });

    const loadData = async () => {
      try {
        await fetchRegions();
        await fetchCabStatus();
        await fetchTripStatistics();
        await fetchTripTrendData();
        await fetchRevenueStatistics();
        await fetchSpecialLocations();
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();

    const interval = setInterval(() => {
      fetchTripStatistics();
      fetchTripTrendData();
      fetchRevenueStatistics();
    }, 10000);

    const interval2 = setInterval(() => {
      fetchCabStatus();
    }, 5000);

    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  useEffect(() => {
    if (regions.length > 0) {
      const firstRegion = regions[0];
      setCenterLatitude(isFinite(firstRegion.latitude) ? firstRegion.latitude : 0);
      setCenterLongitude(isFinite(firstRegion.longitude) ? firstRegion.longitude : 0);
    }
  }, [regions]);



  if (cabData.length <= 0 || !tripStatisticsData || !revenueStatisticsData || !tripTrendData || !regions) {
    return <SemipolarLoading />;
  }
  const handleCabSelected = (id) => {
    const selectedCab = cabData.find(cab => cab.cabId === id);
    if (selectedCab) {
      setCenterLatitude(selectedCab.latitude);
      setCenterLongitude(selectedCab.longitude);
    }
  };
  const cabLocations = (cabData || [])
  .filter(cab => {
    try {
      return cab.permitNumber && isFinite(cab.latitude) && isFinite(cab.longitude);
    } catch (error) {
      console.error('Error filtering cabData:', error);
      return false; 
    }
  })
  .map(data => {
    try {
      return {
        latLng: { lat: data.latitude || 0, lng: data.longitude || 0 },
        title: `Permit: ${data.permitNumber}\r\nHolder: ${data.permitHolderName}\r\n${data.connected === 0 || !data.driverNumber ? '' : `Driver: ${data.driverName}`}`,
        status: data.cabStatus,
        cabId: data.cabId,
        queuePosition: data.queuePosition,
      };
    } catch (error) {
      console.error('Error mapping cabData:', error);
      return {}; 
    }
  });

  const calculateSumForProperty = (data, property) => {
    const periods = ["daily", "weekly", "monthly"];
    return periods.reduce((acc, period) => {
      acc[period] = Object.values(data).reduce((sum, item) => sum + item[property][period], 0);
      return acc;
    }, {});
  };

const calculateSumForRevenue = (data) => {
  const revenueArray = Object.values(data);
  var result = {"daily":0,"weekly":0,"monthly":0}
  revenueArray.forEach(item => {
    result.daily += item.daily
    result.weekly += item.weekly
    result.monthly += item.monthly
  })
  
  
  return result;
};

  const calculateSumForTripTrend = (tripTrendData) => {
    const index = Object.keys(tripTrendData[Object.keys(tripTrendData)[0]]);
    return index.map(index => ({
      completed: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].completed, 0),
      cancelled: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].cancelled, 0),
      booked: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].booked, 0),
      revenue: Object.values(tripTrendData).reduce((sum, item) => sum + item[index].revenue, 0),
      sampleDate: tripTrendData[Object.keys(tripTrendData)[0]][index].sampleDate
    }));
  };

  const sumActive = calculateSumForProperty(tripStatisticsData, "active");
  const sumBookings = calculateSumForProperty(tripStatisticsData, "bookings");
  const sumCancelled = calculateSumForProperty(tripStatisticsData, "cancelled");
  const sumCompleted = calculateSumForProperty(tripStatisticsData, "completed");
  const revenueTotal = calculateSumForRevenue(revenueStatisticsData);
  const tripTrendArray = calculateSumForTripTrend(tripTrendData);
  console.log(revenueTotal)
  return (
    <>
       <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12} key={'Turer'}>
          <TripStatistics title="Aktive turer" active={sumActive} completed={sumCompleted} bookings={sumBookings} cancelled={sumCancelled} />
        </Grid>
        <Grid item md={3} sm={6} xs={12} key={'inntekter'}>
          <RevenueStatistics title="Omsetning" total={revenueTotal}  />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <div className={classes.mainChartHeaderLabels}>
                  <div className={classes.mainChartHeaderLabel}>
                    {/* <Dot color="primary" /> */}
                    <Typography className={classes.mainChartLegentElement}>
                      Siste 30 dager
                    </Typography>
                  </div>
                 </div>
              </div>
            }
          >
            
            <ResponsiveContainer width="100%" minWidth={400} height={150}>
              <ComposedChart
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                data={tripTrendArray}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="sampleDate"/>
                <YAxis yAxisId={1} orientation="right" label={{ value: "kroner", angle: -90, dx:20}}/>
                <YAxis yAxisId={2}/>

                <Tooltip position={{ y: 0 }} />
                <Legend />
                <Area  yAxisId={2} name="Forespørsler" type="monotone" dataKey="booked" fill="#8884d8" stroke="#8884d8" />
                <Bar  yAxisId={2} name="Fullførte turer" dataKey="completed" barSize={20} fill="#413ea0" />
                <Line  yAxisId={2} name="Kansellerte turer" type="monotone" dataKey="cancelled" stroke="#ff7300" />
                <Line  yAxisId={1} name="Omsetning" type="monotone" dataKey="revenue" stroke="#2a703c" strokeWidth={2}/>
                {/* <Scatter dataKey="cnt" fill="red" /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </Widget>
        </Grid>



        <Grid item md={6} sm={6} xs={12}>
           <CabList  data={cabData} onCabSelected={handleCabSelected}></CabList>
          {/* <Widget
            title="Biler"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Table data={tableData} onCabSelected={handleCabSelected}/>
          </Widget> */}
        </Grid>


        <Grid item md={6} sm={6} xs={12}>
          { centerLatitude != 0 &&
            <MapContainer markersData={cabLocations} centerLatitude={centerLatitude} centerLongitude={centerLongitude} specialLocations={specialLocations} />
          }
        </Grid>

      </Grid>
    </>
  );
}

export default Dashboard;
